<template>
    <div id="design-background">
        <div v-if="design?.cover_preview_url">
            <img v-if="typographyPreview" class="align-image" :src="typographyPreview">
            <img class="background" :src="designBackgroundImage" />
        </div>
    </div>
    <section v-show="!showFeed" id="design-canvas" ref="designCanvas">
        <section id="canvas-frontpage">
            <section class="canvas-rectangle">
                <div id="canvas-frontpage-inner" :style="frontPageStyle">
                    <div v-if="subjectImage && store.getters.getImagePosition === 0" class="canvas-image">
                        <img :src="subjectImage" />
                    </div>
                    <div class="canvas-text-wrapper" :style="pagePositionStyle">
                        <div class="canvas-name-dates-wrapper">
                            <p class="canvas-name" :style="nameTextStyle">
                                {{ fullNameComp || translate(store.getters.currentImage.dummy_name) }}
                            </p>
                            <div class="canvas-dates-wrapper dates-line-break dynamic-text-size" v-if="!dateSeparator">
                                <div class="canvas-dates">
                                    <p class="canvas-prefix" v-html="bornPrefix" :style="textStyle"></p>
                                    <p class="canvas-text" :style="textStyle">{{ dob }}</p>
                                    <p class="canvas-prefix" v-html="deathPrefix" :style="textStyle"></p>
                                    <p class="canvas-text" :style="textStyle">{{ dod }}</p>
                                </div>
                            </div>
                            <div class="canvas-dates-wrapper dynamic-text-size" v-else>
                                <p v-if="bornPrefix.length" class="canvas-prefix" v-html="bornPrefix" :style="textStyle"></p>
                                <p class="canvas-text" :style="textStyle">{{ dob }}</p>
                                <p class="canvas-text" v-html="dateSeparator" :style="textStyle"></p>
                                <p v-if="deathPrefix.length" class="canvas-prefix" v-html="deathPrefix" :style="textStyle" ></p>
                                <p class="canvas-text" :style="textStyle">{{ dod }}</p>
                            </div>

                            <div v-if="subjectImage && store.getters.getImagePosition === 1" class="canvas-image below-name">
                                <img :src="subjectImage" />
                            </div>
                        </div>

                        <p class="canvas-time-place dynamic-text-size"
                           :style="textStyle"
                           v-html="ceremony"
                        ></p>
                    </div>
                </div>
            </section>
        </section>
        <section id="canvas-lastpage">
            <div id="canvas-lastpage-inner" :style="lastPageStyle">
                <div class="agency-logo-wrapper" v-if="agencyLogo">
                    <img :src="agencyLogo" alt="" />
                </div>
            </div>
        </section>
    </section>
</template>

<script>

import {computed} from "vue";
import {useStore} from "vuex";
import {typographies, getEffectStyle, getPositionStyle, effects, positions} from "@/config";
import { nl2br, translate } from "@/libs/helper";
import { cross, heart, star } from "@/libs/svgHelper";

export default {
    components: {
    },
    props: {
        design: Object,
        showFeed: Boolean,
        fullNameComp: String,
        initFontSizeText: Number,
        initFontSizeName: Number,
        selectedTextFont: Object,
        selectedNameFont: Object,
    },
    setup(props) {
        const store = useStore();

        const settings = computed(() => {
            return store.state.settings
        })

        const project = computed(() => {
            return store.state.project;
        });

        const dateSeparator = computed(() => {
            switch (settings.value.date_separator) {
                case "space": return "  ";
                case "dash":  return " - ";
                case "break": return false;
                default: return " ";
            }
        });

        const bornPrefix = computed(() => {
            const prefix = settings.value.born_prefix
            if(prefix === "star" || (!prefix && prefix !== ''))
                return star()
            if(prefix === "heart")
                return heart()
            return prefix
        })

        const deathPrefix = computed(() => {
            const prefix = settings.value.dead_prefix
            if(prefix === "cross" || (!prefix && prefix !== ''))
                return cross()
            if(prefix === "heart")
                return heart()
            return prefix
        })

        const dob = computed(()=>{
            return project.value?.subject_dob_formatted || translate("inspo.birth_date");
        });

        const dod = computed(()=>{
            return project.value?.subject_dod_formatted || translate("inspo.death_date");
        });

        const ceremony = computed(()=>{
            if(project.value?.ceremony_formatted) {
                return nl2br(project.value?.ceremony_formatted);
            }
            return translate('inspo.content_place');
        });

        const agencyLogo = computed(() => {
            return props.design?.use_inverted_logo ? settings.value.inverted_logo : settings.value.logo;
        });

        const textStyle = computed(()=>{
            const style = {
                color: props.design?.textcolor || 'black',
                fontSize: props.initFontSizeText,
            };

            if(props.selectedTextFont) {
                style.fontSize = Math.round(style.fontSize * props.selectedTextFont.multiplier*10) / 10;
                style.fontFamily = `'im4 ${props.selectedTextFont['font-family']}'`
            }
            style.fontSize += "px";
            return style;
        });

        const nameTextStyle = computed(()=>{
            const style = {
                color: props.design?.textcolor || 'black',
                fontSize: props.initFontSizeName,
            };

            if(props.selectedNameFont) {
                style.fontSize = Math.round(style.fontSize * props.selectedNameFont.multiplier *10) / 10;
                style.fontFamily = `'im4 ${props.selectedNameFont['font-family']}'`
            }
            style.fontSize += "px";
            return style;
        });

        const pagePositionStyle = computed(()=>{
            const position = positions.find((item) => item.id === store.getters.getPositionId) || positions[0];
            return getPositionStyle(position?.name);
        });

        const subjectImage = computed(()=>{
            let file = store.getters.currentImage.file;
            if(file) {
                if(file === 'project')
                    return project.value?.subject_image ? project.value?.subject_image.replace("thumb.jpg", "preview.jpg") : false;
                return `${store.state.sourceUrl}/images/${file}`
            }
            return false;
        });

        const computedContentPosition = computed(()=>{
            const default_content_position = [10, 4, 10, 4];
            return {
                ...default_content_position,
                ...(props.design?.contentPosition || {}),
            };
        })

        const frontPageStyle = computed(()=> {
            const effect = effects.find((item) => item.id === store.getters.getEffectId) || effects[0];
            const style = getEffectStyle(effect?.name || 'none');
            style.padding = `${computedContentPosition.value[0]}% ${computedContentPosition.value[1]}% ${computedContentPosition.value[2]}% ${computedContentPosition.value[3]}%`;

            if(computedContentPosition.value.folding) {
                const width = (computedContentPosition.value.folding / 50) * 100;
                style.maxWidth = width + "%";
            }
            return style;
        });

        const lastPageStyle = computed(()=>{
            return {
                padding : `${computedContentPosition.value[0]}% ${computedContentPosition.value[1]}% ${computedContentPosition.value[2]}% ${computedContentPosition.value[3]}%`
            }
        })

        const typographyPreview = computed(()=>{
            if(!props.showFeed)
                return null;
            const typography = typographies.find((item)=>item.id == store.getters.getTypographyId);
            return typography ? `${store.state.sourceUrl}/images/${typography.preview}` : null;
        });

        const designBackgroundImage = computed(()=>{
            return (!props.showFeed ? props.design.cover_preview_url : props.design.feed_preview_url) || store.getters.noDesignPath;
        });

        return {
            store,
            translate,
            typographyPreview,
            frontPageStyle,
            lastPageStyle,
            subjectImage,
            pagePositionStyle,
            nameTextStyle,
            textStyle,
            dateSeparator,
            bornPrefix,
            deathPrefix,
            dob,
            dod,
            ceremony,
            agencyLogo,
            designBackgroundImage,
        };
    },
};

</script>

<style scoped >

#design-canvas {
    display: flex;
    flex-direction: row-reverse;
    position: absolute;
    width: 100%;
    height: 100%;
}

#design-background {
    width: 100%;
    position: relative;
}

#design-background img {
    width: 100%;
    object-fit: fill;
    border-radius: 8px;
}

.background{
    background-color: white;
}

.mq-small .background,
.mq-medium .background {
    box-shadow: rgb(0 0 0 / 10%) 0 3px 8px;
}

#canvas-frontpage {
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 50%;
    display: flex;
    justify-content: center;
}

#canvas-frontpage-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    width: 75%;
    margin: 0 auto;
    text-align: center;
}

#canvas-frontpage-inner div {
    width: 100%;
}

#canvas-frontpage-inner p {
    margin: 0;
}

.canvas-rectangle {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
}

.canvas-image {
    max-width: 75% !important;
    max-height: 50%;
    margin: 0 auto;
}
.canvas-image.below-name {
    padding-top: 8%;
}
.canvas-image img {
    height: 100%;
    object-fit: cover;
    border-radius: 0;
    border: 0.25em solid white;
    z-index: 0;
}


.align-image {
    position: absolute;
}

.canvas-text-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.canvas-name-dates-wrapper {
    padding-bottom: 5%;
}

.canvas-name {
    font-size: min(3vw, 2.2em);
    line-height: 1;
    padding-top: 6%;
    padding-bottom: 1.5%;
}

.canvas-dates-wrapper {
    padding-top: 2%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .4em;
}

#canvas-frontpage-inner .canvas-prefix {
    font-family: inherit;
    height: 1em;
    min-width: fit-content;
    width: 1em;
    line-height: 1;
}

.canvas-dates {
    display: grid;
    grid-template-columns: repeat(16, 1fr);
    text-align: left;
    align-items: center;
    gap: .3em;
}

.canvas-dates .canvas-prefix:nth-child(odd) {
    grid-column: 8;
}

.canvas-dates .canvas-text:nth-child(even) {
    grid-column: 9;
}

.canvas-dates .canvas-text {
    width: max-content;
}

.canvas-text {
    font-family: inherit;
    line-height: 1;
    white-space: nowrap;
}

.canvas-time-place {
    padding-top: 5%;
}

#canvas-lastpage {
    width: 100%;
    height: 100%;
}

#canvas-lastpage-inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.agency-logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
}

.agency-logo-wrapper img {
    height: auto;
    border-radius: 0 !important;
}

/* Targets only Firefox */
@-moz-document url-prefix() {
    .canvas-dates p {
        line-height: 1.3;
    }
}

/* Targets only Safari 10+ */
@media not all and (min-resolution:.001dpcm) {
    #canvas-frontpage-inner .canvas-prefix {
        width: auto;
    }
}
</style>
